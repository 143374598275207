.descriptions{
    margin: 4rem 6rem;
}

.service-title{
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.serv-desc{
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: .5rem;
    margin-bottom: 2rem;
    margin: .5rem 4rem 4rem 2rem;
}

@media screen and (max-width: 450px){
    .serv-desc{
        margin-right: 0rem;
        margin-left: 0rem;
        font-size: small;
    }

    .service-title{
        font-size: 2rem;
    }



}