/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
<styl> */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Playfair Display', sans-serif;
}

.App {
    text-align: center;
}

@media screen and (max-width: 850px){
    body{
        width: 100vw;
        overflow-x: hidden;
    }
}